import $ from 'jquery'
import 'popper.js'
import 'bootstrap'
// import Player from '@vimeo/player'
import Swiper, { Navigation, Pagination } from 'swiper'

Swiper.use([Navigation, Pagination]);

$(function() {
  // 言語切替の案内表示
  const languages = window.navigator.languages || [
    window.navigator.language ||
    window.navigator.userLanguage ||
    window.navigator.browserLanguage
  ]

  const currentLocale = $('body').data('locale')

  function toggleLocaleSwitcher() {
    if (localStorage.getItem('hide-locale-switcher') === null && currentLocale === 'ja' && languages[0] !== 'ja') {
      $('.locale-switcher').addClass('d-block').removeClass('d-none')
    } else {
      $('.locale-switcher').addClass('d-none').removeClass('d-block')
    }
  }

  toggleLocaleSwitcher()

  $('.hide-locale-switcher').on('click', function(e) {
    localStorage.setItem('hide-locale-switcher', true)
    toggleLocaleSwitcher()
  })

  // メニューモーダル
  const $modal = $('#title-bar-menu-modal')

  $modal.on('show.bs.modal', function(e) {
    $('body').addClass('title-bar-menu-modal-open')
  })

  $modal.on('hide.bs.modal', function(e) {
    $('body').removeClass('title-bar-menu-modal-open')
  })

  $('.title-bar-menu-btn').on('click', function(e) {
    $('body').hasClass('modal-open') ? $modal.modal('hide') : $modal.modal('show')
  })

  $('.title-bar-menu-item').on('click', function(e) {
    $modal.modal('hide')
  })

  // Vimeo の動画再生
  // const iframe = document.querySelector('iframe#vimeo')
  // const player = new Player(iframe)

  // player.on('play', function() {
  //   console.log('play')
  // })

  // player.on('pause', function() {
  //   console.log('pause')
  // })

  // Instagram のモーダル
  $('.ig-media').on('click', function(e) {
    const target = $(this).data('target')
    let swiper

    $(target).one('show.bs.modal', function(e) {
      swiper = new Swiper(`${target} .swiper-container`, {
        pagination: {
          el: '.swiper-pagination',
        }
      })
    })

    $(target).one('shown.bs.modal', function(e) {
      swiper.update()
    })

    $(target).one('hidden.bs.modal', function(e) {
      swiper.destroy()
    })

    $(target).modal()
  })

  // モデルの革のスワイパー
  new Swiper('.model-upper-leathers .swiper-container', {
    pagination: {
      el: `.swiper-pagination`,
    }
  })

  // オーダーシート
  const $model = $('#order_model_id')
  const $upperLeather = $('#order_upper_leather_id')
  const $upperLeatherColor = $('#order_upper_leather_color_id')
  const $trialShoes = $('input[name="order[trial_shoes]"]')
  const $size = $('#order_size')
  const $trialShoesSize = $('#order_trial_shoes_size')
  const $totalEstimate = $('#total_estimate')

  if ($model.length && $upperLeather.length) {
    // 革の選択肢の切り替え
    const upperLeatherOptions = $upperLeather.data('options')
    const upperLeatherColorOptions = $upperLeatherColor.data('options')

    $model.change(() => {
      const selectedModel = $model.val()
      $('#order_upper_leather_id option').remove()
      $upperLeather.append(upperLeatherOptions[selectedModel].map(([name, id]) => {
        return $('<option>').val(id).text(name)
      }))
      $upperLeather.change()
    })

    $upperLeather.change(() => {
      const selectedUpperLeather = $upperLeather.val()
      $('#order_upper_leather_color_id option').remove()
      $upperLeatherColor.append(upperLeatherColorOptions[selectedUpperLeather].map(([label, id]) => {
        return $('<option>').val(id).text(label)
      }))
      $upperLeatherColor.prop('disabled', $upperLeatherColor.children('option').length <= 0)
    })

    // 見積り金額の表示
    const basicCharge = $totalEstimate.data('basic-charge')
    const upperLeathers = $totalEstimate.data('upper-leathers')

    $upperLeather.change(() => {
      const selectedUpperLeather = $upperLeather.val()
      $totalEstimate.text(`¥${(basicCharge + upperLeathers[selectedUpperLeather]).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`)
    })

    // トライアルシューズの切り替え
    $trialShoes.change(event => {
      $size.prop('disabled', parseInt(event.target.value, 10))
      $trialShoesSize.prop('disabled', !parseInt(event.target.value, 10))
    })
  }
})
